import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: data
  });
}
export function refresh(data) {
  return request({
    url: '/auth/refresh',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: '/admin/admin',
    method: 'get'
  });
}
export function logout() {
  return request({
    url: '/admin/logout',
    method: 'get'
  });
}
export function fetchUserList(query) {
  return request({
    url: '/admin/adminList',
    method: 'get',
    params: query
  });
}
export function createUser(data) {
  return request({
    url: '/admin/admin',
    method: 'post',
    data: data
  });
}
export function updateUser(data) {
  return request({
    url: '/admin/admin/' + data.id,
    method: 'put',
    data: data
  });
}
export function deleteUser(id) {
  return request({
    url: '/users/' + id,
    method: 'delete'
  });
}
export function changePassword(data) {
  return request({
    url: '/admin/change_password',
    method: 'post',
    data: data
  });
}
export function refreshToken() {
  return request({
    baseURL: process.env.VUE_APP_PDS_URL,
    url: '/api/auth/refresh_token',
    method: 'get'
  });
}
export function adminUserList(query) {
  return request({
    url: '/admin/user_list',
    method: 'get',
    params: query
  });
}
export function sendCode(phone, action) {
  return request({
    url: '/admin/smscode',
    method: 'post',
    data: {
      phone: phone,
      action: action
    }
  });
}
export function bindPhone(data) {
  return request({
    url: '/admin/bind_phone',
    method: 'post',
    data: data
  });
}
export function unbindPhone(data) {
  return request({
    url: '/admin/unbind_phone',
    method: 'post',
    data: data
  });
}
export function unbindStaffID(data) {
  return request({
    url: '/admin/unbind_staffid',
    method: 'post',
    data: data
  });
}