import store from '@/store';
export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    // const roles = store.getters && store.getters.roles

    if (value && typeof value === 'string' && value.length > 0) {
      // const permissionRoles = value
      var hasPermission = store.getters.roleAuthList[value] && store.getters.roleAuthList[value].is_any;
      // const hasPermission = roles.some(role => {
      //   return permissionRoles.includes(role)
      // })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
    }
  }
};