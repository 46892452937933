import "core-js/modules/es6.function.name";
import _objectSpread from "/data/devops_data/jenkins_home/workspace/TaskFrontend/admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routes', 'sidebar'])), {}, {
    asyncRouters: function asyncRouters() {
      return this.$store.getters['router/asyncRouters'];
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      if (route.query.route) {
        return JSON.parse(route.query.route).name;
      }
      var meta = route.meta,
        name = route.name;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return name;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};