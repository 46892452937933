// import Vue from 'vue'
// import Router from 'vue-router'
// import Cookies from 'js-cookie'
// Vue.use(Router)

// /* Layout */

// /**
//  * Note: sub-menu only appear when route children.length >= 1
//  * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
//  *
//  * hidden: true                   if set true, item will not show in the sidebar(default is false)
//  * alwaysShow: true               if set true, will always show the root menu
//  *                                if not set alwaysShow, when item has more than one children route,
//  *                                it will becomes nested mode, otherwise not show the root menu
//  * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
//  * name:'router-name'             the name is used by <keep-alive> (must set!!!)
//  * meta : {
//     roles: ['admin','editor']    control the page roles (you can set multiple roles)
//     title: 'title'               the name show in sidebar and breadcrumb (recommend set)
//     icon: 'svg-name'             the icon show in the sidebar
//     breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
//     activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
//   }
//  */

// /**
//  * constantRoutes
//  * a base page that does not have permission requirements
//  * all roles can be accessed
//  */
// export const constantRoutes = [
//   {
//     path: '/login',
//     component: () => import('@/views/login/index'),
//     hidden: true
//   },
//   {
//     path: '/register',
//     component: import('@/views/register/index'),
//     // redirect: '/register/index',
//     name: 'register',
//     meta: { title: '注册', icon: 'excel' },
//     hidden: true,
//     children: [
//       {
//         path: 'index',
//         name: 'registerIndex',
//         component: () => import('@/views/register/step1'),
//         meta: { title: '注册' }
//       }]
//   },
//   {
//     path: '/404',
//     component: () => import('@/views/404'),
//     hidden: true
//   }
//   // {
//   //   path: '/',
//   //   component: Layout,
//   //   redirect: '/dashboard',
//   //   children: [{
//   //     path: 'dashboard',
//   //     name: 'dashboard',
//   //     component: () => import('@/views/dashboard/index'),
//   //     meta: { title: '首页', icon: 'dashboard' }
//   //   }]
//   // }
// ]

// const createRouter = () => new Router({
//   mode: 'hash', // require service support
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes
// })

// const router = createRouter()

// // 全局路由守卫
// router.beforeEach((to, from, next) => {
//   if (Number(Cookies.get('expire')) - new Date().getTime() <= (6 * (60 * 60) * 1000)) {
//     // store.dispatch('user/refreshToken')
//   }
//   next() // 必须使用 next ,执行效果依赖 next 方法的调用参数
// })

// // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

// export default router

import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [{
//     path: 'dashboard',
//     name: 'dashboard',
//     component: () => import('@/views/dashboard/index'),
//     meta: { title: '首页', icon: 'dashboard' }
//   }]
// },
// {
//   path: '/test',
//   name: 'test',
//   component: () => import('@/views/test'),
//   meta: { title: 'test', icon: 'dashboard' }
// }
// {
//   path: '/iframe',
//   name: 'iframe',
//   component: () => import('@/views/iframe'),
//   meta: { title: 'test', icon: 'dashboard' }
// }
];
export var asyncRoutes = [
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    mode: 'hash',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// 全局路由守卫
router.beforeEach(function (to, from, next) {
  // if (Number(Cookies.get('expire')) - new Date().getTime() <= (6 * (60 * 60) * 1000)) {
  // store.dispatch('user/refreshToken')
  // }
  next(); // 必须使用 next ,执行效果依赖 next 方法的调用参数
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;