import request from '@/utils/request';
export function fetchRoleList(query) {
  return request({
    url: '/admin/role',
    method: 'get',
    params: query
  });
}
export function fetchRoleDetail(id) {
  return request({
    url: "/admin/role/".concat(id),
    method: 'get'
  });
}
export function updateRole(id, data) {
  return request({
    url: "/admin/role/".concat(id),
    method: 'put',
    data: data
  });
}

// export function createRole(data) {
//   return request({
//     url: `/admin/role`,
//     method: 'post',
//     data
//   })
// }

export function deleteRole(id) {
  return request({
    url: "/admin/role/".concat(id),
    method: 'delete'
  });
}
export function fetchRoleAuthList() {
  return request({
    url: "/admin/role_auth_list",
    method: 'get'
  });
}