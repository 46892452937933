var state = {};
try {
  state = {
    log: JSON.parse(window.localStorage['log'] || '[]'),
    transactionIndex: JSON.parse(window.localStorage['transactionIndex'] || '[]'),
    payment_order: JSON.parse(window.localStorage['payment_order'] || '[]'),
    statistic: JSON.parse(window.localStorage['statistic'] || '[]'),
    taskManagerIndex: JSON.parse(window.localStorage['taskManagerIndex'] || '[]'),
    userManagerIndex: JSON.parse(window.localStorage['userManagerIndex'] || '[]'),
    data: JSON.parse(window.localStorage['sortData'] || '{}')
  };
} catch (e) {
  console.log(e);
}
var mutations = {
  CHANGE: function CHANGE(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
      window.localStorage[key] = JSON.stringify(value);
    }
  },
  UPDATE: function UPDATE(state, _ref2) {
    var key = _ref2.key,
      value = _ref2.value;
    state.data[key] = value;
    window.localStorage['sortData'] = JSON.stringify(state.data);
  }
};
var actions = {
  change: function change(_ref3, data) {
    var commit = _ref3.commit;
    commit('CHANGE', data);
  },
  update: function update(_ref4, data) {
    var commit = _ref4.commit;
    commit('UPDATE', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};