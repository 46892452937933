import request from '@/utils/request';
export function fetchCorporationBalanceList(id, query) {
  return request({
    url: "/admin/balances/".concat(id),
    method: 'get',
    params: query
  });
}
export function createDeposit(data) {
  return request({
    url: '/balances/deposit',
    method: 'post',
    data: data
  });
}
export function fetchCorporationBalanceLogs(query) {
  return request({
    url: '/balances/logs',
    method: 'get',
    params: query
  });
}
export function CalculateCommission(query) {
  return request({
    url: '/balances/calculate_commission',
    method: 'get',
    params: query
  });
}
export function fetchFundbills(query) {
  return request({
    url: '/fundbills',
    method: 'get',
    params: query
  });
}
export function fetchOrder(query) {
  return request({
    url: '/admin/order',
    method: 'get',
    params: query
  });
}