// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".el-popup-parent--hidden .fixed-header {\n  padding-right: 15px;\n}", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#2b9ef7",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#000f29",
	"menuHover": "rgba(38, 158, 247, 0.7)",
	"subMenuBg": "#000",
	"subMenuHover": "rgba(38, 158, 247, 0.7)",
	"sideBarWidth": "210px",
	"primaryColor": "#2b9ef7"
};
module.exports = exports;
