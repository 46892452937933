import Layout from '@/layout';
import _import from "./_import";
var _asyncRouterHandle = function asyncRouterHandle(asyncRouter) {
  asyncRouter.map(function (item) {
    if (item.component) {
      if (item.component === 'layout') {
        item.component = Layout;
      } else {
        item.component = _import(item.component);
      }
    } else {
      delete item['component'];
    }
    if (item.children) {
      _asyncRouterHandle(item.children);
    }
  });
};
export { _asyncRouterHandle as asyncRouterHandle };