import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
// export default function checkPermission(value) {
//   if (value && value instanceof Array && value.length > 0) {
//     const roles = store.getters && store.getters.roles
//     const permissionRoles = value

//     const hasPermission = roles.some(role => {
//       return permissionRoles.includes(role)
//     })

//     if (!hasPermission) {
//       return false
//     }
//     return true
//   } else {
//     console.error(`need roles! Like v-permission="['admin','editor']"`)
//     return false
//   }
// }

export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = store.getters && store.getters.roles;
    var permissionRoles = value;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else if (typeof value === 'string' && store.getters.roleAuthList[value]) {
    return store.getters.roleAuthList[value].is_any;
  }
  return false;
}