var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _c("img", {
                    staticClass: "sidebar-hider-logo",
                    attrs: { src: require("../../../assets/logohider.png") },
                  }),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _vm.isDemi
                    ? [
                        _c("img", {
                          staticClass: "sidebar-logo",
                          attrs: { src: require("../../../assets/logo.png") },
                        }),
                        _c("h1", { staticClass: "sidebar-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]
                    : _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: { src: require("../../../assets/logoopen.png") },
                      }),
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }