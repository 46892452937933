//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var title = process.env.VUE_APP_TITLE;
var isDemi = process.env.VUE_APP_IS_DEMI === 'true';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      isDemi: isDemi,
      title: title
    };
  }
};