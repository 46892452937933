import "core-js/modules/web.dom.iterable";
import "/data/devops_data/jenkins_home/workspace/TaskFrontend/admin/node_modules/core-js/modules/es6.array.iterator.js";
import "/data/devops_data/jenkins_home/workspace/TaskFrontend/admin/node_modules/core-js/modules/es6.promise.js";
import "/data/devops_data/jenkins_home/workspace/TaskFrontend/admin/node_modules/core-js/modules/es6.object.assign.js";
import "/data/devops_data/jenkins_home/workspace/TaskFrontend/admin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n
import 'wangeditor/release/wangEditor.css';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css
import App from "./App";
import router from "./router";
import store from "./store";
import jisuan from '@/utils/jisuan';
import '@/permission'; // permission control
import '@/icons'; // icon

import { deepCopy } from '@/utils';
import permission from '@/directive/permission';
Vue.directive('p', permission);
if (process.env.NODE_ENV !== 'development') {
  console.log = function () {};
}
function getTreeData(list) {
  var treeData = [];
  var map = {};
  list.forEach(function (item) {
    // item['name'] = item.classifierName
    map[item.id] = item;
  });
  list.forEach(function (item) {
    var parent = map[item.parent_id];
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      treeData.push(item);
    }
  });
  return treeData;
}
Vue.prototype.getTreeData = getTreeData;
Vue.prototype.$jisuan = jisuan;
Vue.prototype.$copyText = function (text, callback) {
  // text: 要复制的内容， callback: 回调
  var tag = document.createElement('input');
  tag.setAttribute('id', 'cp_hgz_input');
  tag.value = text;
  document.getElementsByTagName('body')[0].appendChild(tag);
  document.getElementById('cp_hgz_input').select();
  document.execCommand('copy');
  document.getElementById('cp_hgz_input').remove();
  if (callback) {
    callback(text);
  }
};
Vue.prototype.$deepCopy = deepCopy;

/**
 * @name  getTableData
 * @desc  纯JS前端分页方法
 * @param  {Number} page 当前页码，默认1
 * @param  {Number} pageSize 每页最多显示条数，默认10
 * @param  {Array} totalData 总的数据集，默认为空数组
 * @return {Object} {
    data, //当前页展示数据，数组
    page, //当前页码
    pageSize, //每页最多显示条数
    length, //总的数据条数
  }
**/
Vue.prototype.getTableData = function () {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var pageSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var totalData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var length = totalData.length;
  var tableData = {
    data: [],
    page: page,
    pageSize: pageSize,
    length: length
  };
  if (pageSize >= length) {
    // pageSize大于等于总数据长度，说明只有1页数据或没有数据
    tableData.data = totalData;
    tableData.page = 1; // 直接取第一页
  } else {
    // pageSize小于总数据长度，数据多余1页
    var num = pageSize * (page - 1); // 计算当前页（不含）之前的所有数据总条数
    if (num < length) {
      // 如果当前页之前所有数据总条数小于（不能等于）总的数据集长度，则说明当前页码没有超出最大页码
      var startIndex = num; // 当前页第一条数据在总数据集中的索引
      var endIndex = num + pageSize - 1; // 当前页最后一条数据索引
      tableData.data = totalData.filter(function (_, index) {
        return index >= startIndex && index <= endIndex;
      }); // 当前页数据条数小于每页最大条数时，也按最大条数范围筛取数据
    } else {
      // 当前页码超出最大页码，则计算实际最后一页的page，自动返回最后一页数据
      var size = parseInt(length / pageSize); // 取商
      var rest = length % pageSize; // 取余数
      if (rest > 0) {
        // 余数大于0，说明实际最后一页数据不足pageSize，应该取size+1为最后一条的页码
        tableData.page = size + 1; // 当前页码重置，取size+1
        tableData.data = totalData.filter(function (_, index) {
          return index >= pageSize * size && index <= length;
        });
      } else if (rest === 0) {
        // 余数等于0，最后一页数据条数正好是pageSize
        tableData.page = size; // 当前页码重置，取size
        tableData.data = totalData.filter(function (_, index) {
          return index >= pageSize * (size - 1) && index <= length;
        });
      } // 注：余数不可能小于0
    }
  }
  return tableData.data;
};
// set ElementUI lang to EN
Vue.use(ElementUI, {
  locale: locale
});
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
Vue.config.productionTip = false;
// 设置主域
document.domain = process.env.VUE_APP_DOMAIN;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});