import _objectSpread from "/data/devops_data/jenkins_home/workspace/TaskFrontend/admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//

import { isExternal } from '@/utils/validate';
import { mapState } from 'vuex';
export default {
  props: {
    to: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: function _default() {}
    },
    parent: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: _objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    }
  })),
  methods: {
    isExternal: isExternal,
    clickRouter: function clickRouter() {
      var url;
      if (this.url.substr(0, 6) === 'PAY://') {
        url = process.env.VUE_APP_BMMS_URL + this.url.substring(6);
      } else {
        url = process.env.VUE_APP_PDS_URL + this.url;
      }
      this.$router.push({
        path: "/iframe/go",
        query: {
          route: "".concat(JSON.stringify(this.item)),
          parent: JSON.stringify(this.parent),
          url: url
        }
      });
    },
    linkProps: function linkProps(url) {
      if (isExternal(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        is: 'router-link',
        to: url
      };
    }
  }
};